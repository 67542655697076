import React, { FC } from 'react';

interface Props {

}

const DownloadIcon: FC<Props> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
            <path d="M 11 2.0507812 C 8.992 2.2507813 7.1577813 3.0487187 5.6757812 4.2617188 L 7.1015625 5.6875 C 8.2075625 4.8275 9.542 4.2513594 11 4.0683594 L 11 2.0507812 z M 13 2.0507812 L 13 4.0703125 C 16.94 4.5653125 20 7.9299531 20 12.001953 C 20 16.073953 16.94 19.436641 13 19.931641 L 13 21.951172 C 18.046 21.447172 22 17.177953 22 12.001953 C 22 6.8259531 18.046 2.5537812 13 2.0507812 z M 4.2617188 5.6757812 C 3.0477188 7.1577813 2.2507812 8.992 2.0507812 11 L 4.0683594 11 C 4.2513594 9.542 4.8275 8.2075625 5.6875 7.1015625 L 4.2617188 5.6757812 z M 11 7 L 11 13 L 8 13 L 12 17 L 16 13 L 13 13 L 13 7 L 11 7 z M 2.0507812 12.998047 C 2.2507812 15.007047 3.0477188 16.842219 4.2617188 18.324219 L 5.6894531 16.896484 C 4.8294531 15.790484 4.2533125 14.456047 4.0703125 12.998047 L 2.0507812 12.998047 z M 7.1015625 18.3125 L 5.6757812 19.738281 C 7.1577813 20.951281 8.9900469 21.749219 10.998047 21.949219 L 10.998047 19.931641 C 9.5407978 19.748375 8.2070756 19.172121 7.1015625 18.3125 z" />
        </svg>
    );
};

export default DownloadIcon;
