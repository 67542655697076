import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container } from '../components/grid/grid';
import { IPressPageItem } from '../interfaces/pressPageItem/pressPageItem';
import PressPage from '../components/pressPage';

interface Props {
	data: {
		prismicPressPage: IPressPageItem
	}
}

const Press: FC<Props> = ({ data: { prismicPressPage } }) => {
	return (
		<Container>
			<PressPage pressPage={prismicPressPage} />
		</Container>
	);
};

export default Press;

export const pageQuery = graphql`
	query PressPageBySlug($uid: String!) {
		prismicPressPage(uid: { eq: $uid}) {
			uid
			data {
				title {
					text
				}
				main_text {
					text
				}
				pdf_list_title {
					text
				}
				pdf_list {
					pdf_file {
						url
						name
						size
					}
				}
				photo_list_title {
					text
				}
				photo_list {
					photo_folder {
						url
						name
						size
					}
				}
			}
		}
	}
`;
