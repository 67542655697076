import styled from 'styled-components';
import media from '../../constants/media';

export const Wrapper = styled.div`
	margin: 20rem auto 10rem auto;
    padding: 0 3.5rem;
    ${media.tablet`
        padding: 0 8rem;
	`}
    ${media.desktopSmall`
		margin-top: 35rem;
        padding: 0 16rem;
	`}
`;

export const Title = styled.h4`
    margin-bottom: 2rem;
    color: black;
    font-weight: 700;
`;

export const MainText = styled.div`
    max-width: 90rem;
    margin-bottom: 8rem;
    color: #5A5A5A;
`;

export const List = styled.div`
    max-width: 110rem;
    margin-top: 3rem;
    margin-bottom: 8rem;
`;

export const ListItem = styled.a`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #D5D5D5;
    padding: 2rem;
    &:hover {
        border-color: #0B0B0B;
    }
    div {
        max-width: 80%;
        color: #0B0B0B;
        font-size: 16px;
    }
    ${media.tablet`
        padding: 2rem 4rem;
	`}
`;