import React, { FC } from 'react';
import * as StyledPressPage from './pressPage.styles';
import DownloadIcon from '../icons/download/download';
import IPressPageItem from '../../interfaces/pressPageItem/pressPageItem';

interface Props {
	pressPage: IPressPageItem
}

const PressPage: FC<Props> = ({ pressPage }) => {
	const bytesToSize = (bytes: number) => {
		const sizes = ['bytes', 'kb', 'mb', 'gb', 'tb'];
		if(bytes === 0) return '0 byte';
		const i = Number(Math.floor(Math.log(bytes) / Math.log(1024)));
		return String(Math.round(bytes / (1024 ** i))) + ' ' + sizes[i];
	};

	const fileName = (file) => {
		const bytes = bytesToSize(file.size);
		const name = file.name.substr(0, file.name.indexOf('.'));
		const fileType = file.name
			.substr(file.name.indexOf('.') + 1)
			.toUpperCase();
		return name
			.concat(' - ')
			.concat(fileType)
			.concat(' (')
			.concat(bytes)
			.concat(')');
	};

	return (
		<StyledPressPage.Wrapper>
			<StyledPressPage.Title>
				{pressPage.data.title.text}
			</StyledPressPage.Title>
			<StyledPressPage.MainText>
				{pressPage.data.main_text.text}
			</StyledPressPage.MainText>
			<StyledPressPage.Title>
				{pressPage.data.pdf_list_title.text}
			</StyledPressPage.Title>
			<StyledPressPage.List>
				{pressPage.data.pdf_list.map((pdfFile, index) => (
					<StyledPressPage.ListItem
						key={index}
						href={pdfFile.pdf_file.url}
						download
					>
						<div>{fileName(pdfFile.pdf_file)}</div>
						<DownloadIcon />
					</StyledPressPage.ListItem>
				))}
			</StyledPressPage.List>
			<StyledPressPage.Title>
				{pressPage.data.photo_list_title.text}
			</StyledPressPage.Title>
			<StyledPressPage.List>
				{pressPage.data.photo_list.map((photoFolder, index) => (
					<StyledPressPage.ListItem
						key={index}
						href={photoFolder.photo_folder.url}
						download
					>
						<div>{fileName(photoFolder.photo_folder)}</div>
						<DownloadIcon />
					</StyledPressPage.ListItem>
				))}
			</StyledPressPage.List>
		</StyledPressPage.Wrapper>
	);
};

export default PressPage;
